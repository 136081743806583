import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import settings from './settings'

export default new Vuex.Store({
	modules: {
		settings
	}
})
