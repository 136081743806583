import Vue from 'vue'

import Functions from './functions'
import Directives from './directives'

Vue.prototype.$console = 'color:coral'

Vue.prototype.$imgUrl = Functions.imgUrl
Vue.prototype.$compareValues = Functions.compareValues
Vue.prototype.$removeOrphans = Functions.removeOrphans

Vue.directive('click-outside', Directives.click_outside)
