<template>
	<main id="home" class="home">
		<section class="home-main">
			<div class="home-logo">
				<img :src="$imgUrl('anex_logo_white.svg')" alt="logo">
			</div>
			<div class="home-text">
				<ul class="home-list">
					<li>{{ $t('service.windows') }}</li>
					<li>{{ $t('service.doors') }}</li>
					<li>{{ $t('service.facades') }}</li>
				</ul>
				<a :href="`tel:${$t('external.phone').split(' ').join('')}`">
					{{ $t('external.phone') }}
				</a>
				<a :href="`mailto:${$t('external.email')}`">
					{{ $t('external.email') }}
				</a>
			</div>
		</section>
		<section class="home-square">
			<img :src="$imgUrl('anex_square.svg')" alt="square">
		</section>
	</main>
</template>

<script>
export default {
}
</script>
